.navbar {
  background-image: linear-gradient(-60deg, #cd1719 0%, #ac3d49 48%, #1c2972 98%);
  padding-top: 26px;
  padding-bottom: 14px;
  z-index: 999999999;
  &--scrolling {
    transition: 1s ease;
    padding-top: 15px;
    padding-bottom: 10px;
    .head__navbar {
      margin-top: 0px;
      ul {
        li {
          &:hover {
            border-top: 5px solid white;
            padding-top: 15px;
            margin-top: -30px;
          }
        }
      }

    }
    .head__logo {
      img {
        height: 75px;
        padding-top: 10px;
      }
    }
    .head__contact {
      margin-top: 0px;
    }
  }
  .head {
    &__logo {
      padding-left: 0px;
      padding-right: 0px;
      img {
        transition: 1s ease;
        margin-top: -15px;
      }
    }
    &__navbar {
      padding-top: 5px;
      ul {
        text-align: center;
      }
      li:nth-child(even) {
        a {
          border-left: 0;
          border-right: 0;
        }
        margin-left: 0;
        margin-right: 0;
      }
      li:last-child, li:nth-last-child(2) {
        a {
          padding-top: 12px;
        }
      }
      li {
        display: inline-block;
        text-align: center;
        vertical-align: bottom;
        transition: 0.2s ease;
        &:hover {
          border-top: 5px solid white;
          padding-top: 26px;
          margin-top: -34px;
        }
        a {

          border-left: 1px solid white;
          border-right: 1px solid white;

          height: 48px;
          font-family: "sofia-pro", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #ffffff;
          letter-spacing: 0.23px;
          line-height: 24px;
          text-align: center;
          transition: 0.2s ease;
          display: block;

          padding: 0px 18px;
          @media screen and (min-width: $screen-lg-min) {
            padding: 0px 24px;
          }
          &:hover {
            text-decoration: none;
          }
        }
        .single-line {
          line-height: 48px !important;
        }
        .last {
          border-right: 1px solid white;
        }
      }

    }
    &__contact {
      margin-top: 5px;
      padding: 6px 15px;
      text-align: center;
      font-family: "sofia-pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      background: #ffffff;
      border-radius: 3px;
      border: 1px solid white;
      color: $c-blue;
      @media screen and (max-width: $screen-lg-min) {
        font-size: 12px;
      }
    }
  }

}

.floating-logo {
  position: fixed;
  width: 100%;
  background: white;
  z-index: 99999999;
  left: 0px;
  top: 0px;
  img {
    display: inline-block !important;
    margin-top: 0px;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      margin-top: -15px;
    }
  }
  .language_school {
    display: none;
    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
      display: inline-block !important;
    }
    color: black;
    font-size: 30px;
    font-weight: 400;
    margin-top: 15px;
    margin-left: 15px;
    text-transform: uppercase;
  }
}

.contact-box {
  padding-top: 150px;
  padding-bottom: 150px;
}