$screen-lg: 1300px;
$container-large-desktop: 1270px;
$font-size-h1: 32px;
$font-size-h2: 27px;
$font-size-h3: 21px;
$font-size-h4: 18px;
$font-size-h5: 16px;
$font-size-h6: 13px;
$font-size-base: 16px;

// Variables go here
$c-green: #9fd406;
$c-green-dark: #5D7D00;

$c-yellow: #ffcc07;
$c-yellow-dark: #C77D00;

$c-pink: #fa5dc6;
$c-pink-dark: #ff00aa;

$c-grey: #2d2d2d;

$c-red: #cd1719;
$c-red-dark:#a2141d;
$c-blue: #1e3179; 
$c-blue-dark: #150a2d;
// temporary, cant get the hex...