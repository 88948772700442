.branding {
	margin: 85px 0px;
	overflow:visible;
	position:relative;
	&__header {
		h3 {
			font-weight: 400;
			font-size:24px;
			color: $c-blue-dark;
			letter-spacing:1.42px;
		}
		margin-bottom:50px;
	}
	&__image {
		img {
			margin: 0 auto;
			display:block;
			@media screen and (min-width: $screen-lg-min){
				float:right;
			}
		}
		margin-bottom: 15px;
		@media screen and (min-width: $screen-sm-min){
		  margin-bottom: 0px;
		}
	}
	&__text {
		font-family: "sofia-pro",sans-serif;
		font-style: normal;
		font-weight: 300;
		font-size:18px;
		color:#150a2d;
		letter-spacing:0px;
		text-align:left;
		max-width:600px;
		@media screen and (min-width: $screen-sm-min){
		  font-size:20px;
		}
		@media screen and (min-width: $screen-lg-min){
			margin-left:35px;
		}
	}
	.arrow {
			width: 280px;
			height: 280px;
			background: red;
			-webkit-clip-path: polygon(50% 100%, 0 80%, 100% 80%);
			clip-path: polygon(50% 100%, 0 80%, 100% 80%);
	}

	.arrow-down {
		position: absolute;
		left: 50%;
		bottom: -94px;
		z-index: 999999;
		width: 0;
		// temporary and ugly
		width: 0; 
		height: 0; 
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-top: 10px solid white;
	}

	.swiper-pagination {
		position:relative;
		margin-top: 80px;
		margin-bottom: 70px;
		.swiper-pagination-bullet {
			margin: 0 15px;
		}
	}
}