.references {
	background: linear-gradient(
		rgba(162, 99, 20, 0.37),
		rgba(246, 81, 62, 0.37)
		), url('../images/references.jpg') fixed; 
	background-size: cover;
	.swiper-pagination {
		position:relative;
		margin-top: 45px;
		margin-bottom: 0px;
		.swiper-pagination-bullet {
			margin: 0 15px;
		}
	}
	&__content {
		z-index:2;
	}
	&__text {
		font-size:25px;
		color:#ffffff;
		letter-spacing:0.32px;
		text-align:left;
		max-width:90%;
		margin: 0 auto;
		@media screen and (min-width: $screen-sm-min){
		 font-size:30px;
		}
	}
	&__source {
		font-weight: 400;
		font-size:16px;

		color:$c-blue;
		letter-spacing:0.25px;
		// margin-left: 20px;
		margin-top:15px;
	}
	&__button-block {
		@media screen and (max-width: $screen-sm-min){
		    text-align:center;
		  }
	}
	&__button {

		margin-bottom: 65px;
		.col-sm-6 {
				@media only screen 
				and (min-device-width : 768px) 
				and (max-device-width : 1024px)  { 
				margin-left: 25% !important;
			}
			@media only screen 
			and (min-device-width : 768px) 
			and (max-device-width : 1024px) 
			and (orientation : landscape) {
				margin-left: 33.33% !important;
			}
		}
	}
	&__image {
		z-index:-11;
		display:inline-block;
		margin-bottom: -50px;
		margin-left: -15px;
		@media screen and (min-width: $screen-sm-min){
		 margin-left: -50px;
		}
	}
	img {
	
	}
}