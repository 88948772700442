.user-content {

    &--white {
        color: white;
    }
    &--red {
        h1, h2, h3, h4, h5, h6, p, strong, ul {
            color: $c-red !important;
        }
    }
    &--left {
        h1, h2, h3, h4, h5, h6, p, strong, ul {
            text-align: left !important;
        }
    }
    h1, h2, h3, h4, h5 {
        transition: 0.2s ease;
        margin: 50px auto 65px;
        text-align: center;
        color: white;
        font-family: "sofia-pro", sans-serif;
        font-style: normal;
        font-weight: 900;

    }
    h2 {
        font-size: 36px;
        font-weight: 900;
        @media screen and (min-width: $screen-sm-min) {
            font-size: 50px;
        }
    }
    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 46px;
        color: #ffffff;
        letter-spacing: 0.12px;
        line-height: 37px;
        text-align: center;
    }
    h6 {
        font-family: "sofia-pro", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        color: #ffffff;
        letter-spacing: 0px;
        line-height: 20px;
        text-align: center;
    }
    ul {
        padding: 0 50px;

        li {
            position: relative;
            list-style: none;
            padding-left: 20px;
            line-height: 1.6em;
            li
            &:before {
                position: absolute;
                content: ' ';
                background: $c-pink;
                width: 10px;
                height: 2px;
                top: 13px;
                left: 0;
            }
            font-size: 1.2em;
        }
    }
    &--top-align {
        &:first-child {
            h1, h2, h3, h4, h5 {
                margin: 0;
                @media screen and (min-width: $screen-md) {
                    padding: 0 40px 15px;
                }
            }
        }
    }
    p {
        font-weight: 300;
        font-size: 16px;
        color: #150a2d;
        letter-spacing: 0px;
        line-height: 20px;
        text-align: justify;
        @media screen and (min-width: $screen-md) {
            // padding: 15px 40px;
        }
    }
    blockquote {
        font-weight: 200;
        font-size: 30px;
        color: $c-red;
        text-align: left;
        border-left: 1px solid $c-red;
        padding-left: 40px;
    }
    strong {
        font-weight: 700;
        font-size: 24px;
        color: #101012;
        letter-spacing: 0.06px;
        line-height: 37px;
    }
    img {
        padding: 25px 0px;
        @media screen and (min-width: $screen-sm) {
            padding: 25px 25px 25px 0px;
        }
    }
    .typo3-neos-alignment- {
        &-left {
            text-align: left;
            img {
                float: left
            }
        }
        &-center {
            text-align: center;
            img {
                margin: 0 auto;
            }
        }
        &-right {
            text-align: r;
            img {
                float: right
            }
        }
    }
    .btn {
        margin: 10px auto;
        @media screen and (min-width: $screen-md) {
            margin: 20px 0px;
        }
        //padding: 15px 20px;
    }
}

.textpage__content {
    padding-top: 150px;
}