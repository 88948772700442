// top 50
// bottom 65

// equalize all spaces top and bottom

.banner {
    background: linear-gradient(
                    rgba(207, 23, 26, 0.10),
                    rgba(207, 23, 26, 0.10)
    ), url('../images/banner.png') fixed;
    background-size: cover;
    // change to max once placeholders removed
    &--mainpage {
        background: linear-gradient(
                        rgba(207, 23, 26, 0.10),
                        rgba(207, 23, 26, 0.10)
        ), url('../images/banner-mainpage.png') fixed;
        background-size: cover;
        position: relative;
    }
    &__logo {
        margin-top: 100px;
        @media screen and (min-width: $screen-sm-min) {
            margin-top: 200px;
        }
        img {
            margin: 0 auto;
            display: block;
        }
    }
    &__maintext {
        font-family: "sofia-pro", sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 36px;
        background: -webkit-linear-gradient(54deg, #cd1719 0%, #ac3d49 49%, #1c2972 100%);
        span {
            background: -webkit-linear-gradient(54deg, #1c2972 0%, #ac3d49 49%, #cd1719 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        max-width: 700px;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-top: 250px;
        text-align: center;
        @media screen and (min-width: $screen-md-min) {
            text-align: left;
            font-size: 50px;
            line-height: 60px;
            margin-bottom: 25px;
        }
        &--landingpage {
            margin-top: 125px;
            @media screen and (min-width: $screen-md-min) {
                margin-top: 250px;
            }
        }
        &--mainpage {
            font-size: 60px;
            line-height: 40px;
            max-width: 100%;
            text-align: center;
            margin-top: 125px;
            padding-bottom: 125px;
            span {
                font-size: 30px;
            }
            @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
                font-size: 85px !important;
                span {
                    font-size: 85px !important;
                }
            }
            @media only screen and (height: 800px) and (width: 600px) {
                font-size: 85px !important;
                span {
                    font-size: 85px !important;
                }
            }
            @media screen and (min-width: $screen-sm-min) {
            }
            @media screen and (min-width: $screen-sm-min) {
                font-size: 60px;
                span {
                    font-size: 60px !important;
                }
            }
            @media screen and (min-width: $screen-md-min) {
                font-size: 100px;
                line-height: 100px;
                span {
                    font-size: 100px !important;
                }
            }
        }
    }
    &__subtext {
        font-family: "sofia-pro", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        color: #ffffff;
        letter-spacing:0;
        text-align: center;
        margin-bottom: 125px;
        @media screen and (min-width: $screen-sm-min) and (max-width: $screen-md-min) {
            .col-sm-offset-2 {
                margin-left: 16.66667%;
            }
        }
        @media screen and (max-width: $screen-md-min) {
            margin: 0 auto;
        }
        @media screen and (min-width: $screen-md-min) {
            text-align: left;
        }
        //&--no-gutter {
        //    margin-bottom: 50px;
        //}
    }
    &__button {
        margin-bottom: 50px;
        @media screen and (min-width: $screen-sm-min + 1) {
            position: absolute;
            bottom:0;
            margin-bottom:0;
        }
    }
    &__pupils {
        // position:absolute;
        bottom:0;
        text-align: center;
        img {
            display: inline-block;
        }
    }
}

.bottom-banner {
    background-image: linear-gradient(-60deg, #cd1719 0%, #ac3d49 48%, #1c2972 98%);
    &__content {
        font-family: "sofia-pro", sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 24px;
        color: white;
        @media screen and (min-width: $screen-sm-min) {
            padding-bottom: 50px;
            padding-top:0;
        }
        &--mainpage {
            font-weight: 300;
            font-size: 16px;
            color: #ffffff;
            letter-spacing:0;
            line-height: 20px;
            text-align: left;
            padding-top:0;
            @media screen and (min-width: $screen-sm-min) {
                padding-bottom: 65px;
            }
        }
    }

    .arrow-down {
        position: absolute;
        left: 47%;
        // temporary and ugly
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-top: 40px solid #ac3d49;
    }
    &__block {
        margin-bottom: 15px;
        font-size: 16px;
        @media screen and (min-width: $screen-sm-min) {
            font-size: 20px;
            margin-bottom:0;
        }
    }
}

