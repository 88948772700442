.questionbox {
  margin-top: 65px;
  margin-bottom: 90px;
  .btn {
    margin-bottom: 0;
  }
  .user-content {
    p {
      max-width: 350px;
    }
  }
  &__content {
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    color: #150a2d;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: left;
  }
  &__inputs {
    margin-top: 35px;
    .col-sm-12 {
      padding-left: 0px;
      @media screen and (min-width: $screen-md) {
        padding-left: 15px;
      }
    }
  }
  h1, h2, h3 {
    color: $c-red;
    text-align: left;
    margin-top: 0;
  }
  span {
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: $c-red-dark;
    text-align: left;
  }
  input {
    margin-bottom: -5px;
    margin-top: 5px;
    padding: 5px 5px 5px 10px;
    border: 1px solid $c-blue;
    border-radius: 3px;
    min-width: 100%;
    @media screen and (min-width: $screen-md) {
      // min-width:480px;
      margin-bottom: 20px;
      min-width: 100%;
    }
  }
  textarea {
    margin-bottom: -5px;
    margin-top: 5px;
    padding: 5px 5px 5px 10px;
    border: 1px solid $c-blue;
    border-radius: 3px;
    min-width: 100%;
    @media screen and (min-width: $screen-md) {
      // min-width:480px;
      margin-bottom: 20px;
      min-width: 100%;
    }
  }
  .content {
    padding: 5px 5px 150px 15px;
  }
  ::-webkit-input-placeholder {
    color: $c-blue-dark;
  }
  :-moz-placeholder {
    color: $c-blue-dark;
    opacity: 1;
  }
  ::-moz-placeholder {
    color: $c-blue-dark;
    opacity: 1;
  }
  :-ms-input-placeholder {
    color: $c-blue-dark;
  }
}

.parsley-errors-list {
  list-style: none;
  color: $c-red;
  padding-left: 0px;
  margin-top: 15px;
  text-decoration: underline;
  @media screen and (min-width: $screen-md) {
    margin-top: -15px;
    margin-bottom: 15px;
  }
}