html, body {
	font-family: "sofia-pro",sans-serif;
	font-style: normal;
	font-weight: 300;
}

a {
	// font-family: "sofia-pro",sans-serif;
	font-family: "Playfair",sans-serif;
	font-weight: 500;
}


.container-fluid {
	overflow:hidden;
}

.col-center {
    display:inline-block;
    float:none;
    text-align:left;
    margin-right:-4px;
}