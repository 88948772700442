.devhero_news_news,.blogpage {
	margin-top: 75px;
	@media screen and (min-width: $screen-md-min + 1){
		margin-top: 150px;
	  }

	.article {
		margin-bottom: 70px;
		padding-left: 0;
		padding-right: 0;
		@media screen and (min-width: $screen-md-min + 1){
			padding-left: 15px;
			padding-right: 15px;
		}
		&__title {
			padding-right: 0;
			@media screen and (min-width: $screen-md-min + 1){
				padding-right: 15px;
			  }
			  @media only screen 
			  and (height : 800px) 
			  and (width : 600px)  { 
			  	width:50%;
			  }
			h3 {
				max-width:270px;
				font-size:14px;
				@media screen and (min-width: $screen-md-min + 1){
					font-size:24px;
					letter-spacing:1.42px;
				  }
				a {
					font-weight: 400;
					color:$c-blue;
					text-align:left;
					
				}
			}
		}
		&__image {
			position:relative;
			img {
				border-top: 5px solid $c-red;
				width: 100%;
			}
		}
		&__tag {
			position:absolute;
			top:0px;
			left: 15px;
			background: $c-red;
			padding:10px 25px;
			a {
				color: white;
				font-weight: 700;
				font-size:14.16px;
				letter-spacing:0px;
				line-height:20px;
				text-align:center;
			}
		}
		&__date {
			.visible-xs {
				text-align:left !important;
			}
			@media only screen 
			and (height : 800px) 
			and (width : 600px)  { 
				width:50%;
			}
			text-align:right;
			padding-top: 22px;
			a {
				font-weight: 600;
				font-size:14.16px;
				color:#1e3179;
				letter-spacing:0px;
				line-height:20px;
				text-align:center;
			}
		}
		&__content {
			margin-bottom:25px;
			p {
				margin-top: 25px;
				font-weight: 300;
				font-size:15px;
				color:$c-blue-dark;
				letter-spacing:0px;
				line-height:20px;
				text-align:left;
			}
		}
		&__bottombar {
			a {
				color: $c-blue;
				font-size:12px;
				@media screen and (min-width: $screen-sm-min + 1){
					font-size: 16px;
				  }
			}
			.commentaries {
				@media screen and (max-width: 360px){
					display:block;
					margin-top:15px;
					margin-bottom:10px;	
			  }
			}
			&__social {
				display: flex;
				align-items: center;
				justify-content: center;
				padding-left: 0;
				@media screen and (min-width: $screen-sm-min + 1){
					padding-left: 15px;
				  }
			}
			&__btn {
			
			}
			.fa {
				margin-right:35px;
				font-size:18px;
				color: $c-blue;
				transition: 0.2s ease;
				@media screen and (min-width: $screen-sm-min + 1){
					margin-right: 32px;
				  }
				&:hover {
					color: $c-red;
				}
			}
		}
		&__button {
			text-align:left;
			padding-left: 0;
			@media screen and (min-width: $screen-sm-min + 1){
				text-align:right; 
			  }
		}
	}
	.sidebar {
		border: 1px solid rgba(205, 23, 25, 0.2);
		padding: 80px 25px;
		&__subtext {
			font-weight: 700;
			font-size:24px;
			color:$c-blue;
			letter-spacing:-0.22px;
			text-align:left;
		}
		&__maintext {
			font-weight: 900;
			font-size:34px;
			color:$c-red;
			@media screen and (min-width: $screen-lg-min + 1){
			    font-size:60px;
				line-height:63px;
				letter-spacing:-0.55px;
			  }
			text-align:left;
		}
	}
}

.article {
	&__social {
		padding-left: 0;
	}
	&__button {
		padding-right:0px;
	}
}