.foot {
  background-image: linear-gradient(58deg, #cd1719 0%, #ac3d49 49%, #1c2972 100%);
  color: white;
  padding: 45px 0;
  &__right {
    margin-top: 75px;
  }
  &__sitemap {
    margin-top: 50px;
    @media screen and (min-width: $screen-sm-min) {
      margin-top: 0px;
    }
    ul {
      margin-bottom: 0;
    }
    li {
      padding-bottom: 25px;
      text-align: center;
      @media screen and (min-width: $screen-sm-min) {
        text-align: left;
      }
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
  a {
    color: white;
    font-family: "sofia-pro", sans-serif;
    font-weight: 300;
  }
  &__logo {
    margin: 0 auto;
    text-align: center;
    padding: 25px 0px;
    @media screen and (min-width: $screen-sm-min) {
      border-left: 1px solid white;
      padding: 0px 0px;
    }
    // img {
    // 	padding-left: 15px;
    // 	padding-right:15px;
    // }
  }
  &__contact {
    text-align: center;
    @media screen and (min-width: $screen-sm-min) {
      text-align: left;
    }
  }
}

.credits {
  background: $c-blue-dark;
  font-size: 14px;
  color: white;
  letter-spacing: 0.14px;
  line-height: 20px;
  text-align: center;
  @media screen and (min-width: $screen-sm-min) {
    text-align: right;
  }
  .row {

    padding: 15px;
  }
  &__holborn {
    @media screen and (min-width: $screen-sm-min) {
      text-align: left;
    }
  }
  &__brand {
    img {
      padding-left: 5px;
      display: inline-block;
      height: 15px;
      width: auto;
    }
  }
}

