.services {
  position: relative;
  &__content {
    padding-top: 80px;
  }
  &__image {
    text-align: center;
    margin-bottom: 15px;
    img {
      margin: 0 auto;
    }
    @media screen and (min-width: $screen-sm) {
      text-align: right;
      margin-bottom: 0px;
    }
  }
  &__title {
    color: $c-blue-dark;
    text-align: center;
    @media screen and (min-width: $screen-sm) {
      text-align: left;
    }
    h3 {
      margin-top: 5px;
      font-weight: 400;
      font-size: 21px;
      letter-spacing: 1.5px;
      @media screen and (min-width: $screen-lg) {
        font-size: 24px;
      }
    }
  }
  &__text {
    font-weight: 300;
    font-size: 16px;
    color: $c-blue-dark;
    letter-spacing: 0px;
    line-height: 20px;
    text-align: center;
    @media screen and (min-width: $screen-sm) {
      text-align: left;
    }
  }
  &__block {
    //margin-bottom: 40px;
    //@media screen and (min-width: $screen-sm) {
    //    margin-bottom: 70px;
    //}
    &--no-margin {
      @media screen and (min-width: $screen-sm) {
        margin-bottom: 0px;
      }
    }
  }
  .arrow-down {
    position: absolute;
    left: 47%;
    // temporary and ugly
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}