.box {
  &__header {
    padding: 65px 0 15px;
    font-size: 36px;
    font-weight: 900;
    transition: 0.2s ease;
    margin: 0 auto 30px;
    text-align: center;
    color: white;
    font-family: "sofia-pro", sans-serif;
    font-style: normal;
    h1, h2, h3, h4, h5, h6 {
      margin-top: 0;
      margin-bottom: 0;
    }
    @media screen and (min-width: $screen-sm-min) {
      font-size: 50px;
    }

    &--bigger {
      @media screen and (min-width: $screen-sm-min) {
        font-size: 60px;
      }
    }
    &--red {

      color: $c-red;
      font-size: 36px;
      @media screen and (min-width: $screen-sm-min) {
        font-size: 60px;
        line-height: 63px;
        letter-spacing: -0.55px;
      }
      @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        font-size: 50px;

      }

    }
    &--left {
      text-align: left;
    }
  }

  &__text {
    font-size: 24px;
    font-weight: 500;
    &--smaller {
      padding-top: 15px;
      font-size: 20px;
    }
  }

  &__content {
    padding-bottom: 45px;
    @media screen and (min-width: $screen-sm) {
      padding-bottom: 65px;
    }
  }

  &__cta {
    margin: 20px auto 30px;
    text-align: center;
    &--gutter {
      margin-top: 35px;
    }
  }
}