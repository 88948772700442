.prices {
	background: linear-gradient(-60deg, rgba(205, 23, 25, 0.85) 0%, rgba(172, 61, 73, 0.85) 48%, rgba(28, 41, 114, 0.85) 98%)
    , url('../images/prices.png') fixed;
	// , linear-gradient(-60deg, #1c2972 0%, #ac3d49 48%, #cd1719 98%)
	background-size: cover;
	// min-height: 700px;
	&__block {
		border: 1px solid white;
		padding:35px;
		min-height:480px;
		color:white;
		@media screen and (min-width: $screen-sm-min){
			max-width: 405px;
			margin-right: 10px;
		}
		@media screen and (min-width: $screen-md-min){
			max-width:285px;
			margin-left: 17.5px;
			margin-right: 17.5px;
		}
		@media screen and (min-width: $screen-lg-min){
			max-width:335px; 
			margin-left: 17.5px;
			margin-right: 17.5px;
		}
		// &:first-child {
		// 	margin-left:35px;
		// }
		&:last-child {
			@media screen and (min-width: $screen-sm-min){
				margin-right:0px;
			}
		}
		margin-bottom: 15px;
		@media screen and (min-width: $screen-md-min){
			margin-bottom: 0px;
			margin-left: 17.5px;
			margin-right: 17.5px;
		}
	}
	&__block-vertical {
			max-height:160px;
			min-width:100%;
			border: 1px solid white;
			color:white;
	}
	&__month {
		.prices__saving {
			background: transparent;
		}
	}
	&__title {
		border-bottom: 1px solid white;
		padding-bottom: 25px;
		text-align: center;
	}
	&__price {
		text-align: center;
		h2{
			font-weight: 800;
		}
		h3 {
			margin-bottom: 10px;
		}
		padding-top: 45px;
		padding-bottom: 40px;
		span {
			color:#150a2d;
		}

	}
	&__saving {
		font-weight:300;
		font-size:15px;
		color:#150a2d;
		letter-spacing:0px;
		line-height:20px;
		margin-bottom:50px;
	}
	&--none {
		background-image:linear-gradient(-60deg, rgba(205, 23, 25, 0.6) 0%, rgba(172, 61, 73, 0.6) 48%, rgba(28, 41, 114, 0.6) 98%) !important;
	}
	&__saving--1 {
			background-image:linear-gradient(-60deg, rgba(205, 23, 25, 0.8) 0%, rgba(172, 61, 73, 0.8) 48%, rgba(28, 41, 114, 0.8) 98%);
	}
	&__content {
		padding-bottom: 50px;
	}
	&__header {
		// margin-top:66px;
		// margin-bottom:53px;
		h2 {
			font-size:30px;
			font-weight: 900;
			color:#ffffff;
			letter-spacing:-0.55px;
			text-transform:none;
			@media screen and (min-width: $screen-sm-min){
				font-size:60px;
				line-height:63px;
			}
		}
	}
}