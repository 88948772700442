.btn {

  font-size: 14px;
  font-family: "sofia-pro", sans-serif;
  font-style: normal;
  font-weight: 300;

  color: $c-blue;
  background: transparent;

  border: 1px solid $c-blue;
  border-radius: 3px;

  transition: 0.4s ease;

  margin: 0 auto;
  margin-top: 10px;
  padding: 15px 30px;
  display: block;
  @media screen and (min-width: $screen-sm-min) {
    display: inline-block;
    font-size: 22px;
    padding: 15px 85px;
  }

  &:hover {
    color: white;
    background-color: $c-blue;
  }

  &--banner {
    margin-top: 10px;
    text-align: center;
    @media screen and (min-width: $screen-sm-min + 1) {
      margin-top: -15px;
      float: right;
    }
    text-transform: uppercase;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 30px;
    }
  }

  &--prices {
    display: table;
    // to remove
    background: #ffffff;
    padding: 10px 10px;
    font-size: 14px;
    font-family: "sofia-pro", sans-serif;
    font-weight: 400;
    color: $c-red-dark;
    border: 1px solid white;
    @media screen and (min-width: $screen-sm-min) {
      padding: 10px 35px;
    }
    &:hover {
      background: $c-red-dark;
      color: white;
      border: 1px solid $c-red-dark;
    }
  }
  &--prices-subbutton {
    margin-top: 45px;
    padding: 10px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 10px 75px;
    }
    font-weight: 500;
    font-size: 14px;
    color: #1e3179;
    letter-spacing: 1.6px;
    text-align: center;
  }

  &--blogpage {
    margin-top: 0px !important;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 35px;
    }
    &:hover {
      color: white !important;
    }
  }

  &--article {
    margin-top: 10px;
    @media screen and (min-width: $screen-sm-min + 1) {
      margin-top: -15px;
    }
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 35px;
    }
    &:hover {
      color: white !important;
    }
  }

  &--sidebar {
    float: right;
    text-transform: uppercase;
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 35px;
    }
    @media screen and (min-width: $screen-lg-min) {
      font-size: 14px;
    }

  }

  &--services {
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    text-align: center;
    margin: 30px auto;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 20px;
      float: right;
    }
  }

  &--references {
    font-family: "sofia-pro", sans-serif;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 1.6px;
    padding: 15px 15px;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 20px;
      font-size: 14px;
      margin-left: 0;
    }
  }

  &--swiper {
    display: table;
    // temporary
    margin: 0 auto;
    font-weight: 500;
    font-size: 14px;
    color: #1e3179;
    letter-spacing: 1.6px;
    text-align: center;
    @media screen and (min-width: $screen-sm-min) {
      padding: 15px 35px;
    }
  }
}

